import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormConfig } from '../../models/Form';
import TemplateFormService from '../../services/TemplateFormService';
import { Option } from '../../components/Option';
import Button, { ButtonType } from '../../components/shared/form-control/Button';
import { SearchInput } from '../../components/shared/form-control/SearchInput';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import { FormType } from '../../models/FormTypes';
import useDebounce from '../../hooks/useDebounce';
import { ApiResponse } from '../../models/ApiResponse';
import { useTranslation } from 'react-i18next';
import FilterTag, { FilterSelectMode } from '../../components/shared/tags/FilterTag';
import ClientTemplateFormService from '../../services/ClientTemplateFormService';
import { nextTick } from '../../utils/ReactUtils';
import ResourceTemplateList from './ResourceTemplateList';
import PlusIcon from '../../components/shared/icon/PlusIcon';
import { useCurrentClient, useCurrentTenantId } from '../../global-state/Clients';

const ResourceTemplates: FC = () => {
  const currentClient = useCurrentClient((x) => x.value);
  const currentTenantId = useCurrentTenantId((x) => x.value);
  const [loading, setLoading] = useState(false);
  const pageContentRef = useRef<HTMLDivElement>(null);
  const [resources, setResources] = useState<ApiResponse<FormConfig[]> | null>(null);
  const [sortBy, setSortBy] = useState('+code');
  const [currentPage, setCurrentPage] = useState(1);
  const [resourceSearchPhrase, setResourceSearchPhrase] = useState('');
  const debouncedSearchTerm = useDebounce(resourceSearchPhrase, 500);
  const { t } = useTranslation(['common', 'resources']);

  const navigate = useNavigate();

  const templateService = useMemo(() => {
    if (currentClient) {
      return new ClientTemplateFormService(currentClient.id);
    }
    return TemplateFormService;
  }, [currentClient]);

  const [statusFilters, setStatusFilters] = useState([
    {
      id: 'active',
      value: false,
      text: t('resources:status.active'),
    },
    {
      id: 'archived',
      value: false,
      text: t('resources:status.archived'),
    },
  ]);

  const onSortBy = (expression: string) => {
    setResources(null);
    setCurrentPage(1);
    setSortBy(expression);
  };

  const shouldIncludeArchived = useMemo(() => {
    if (!statusFilters.find((filter) => filter.id === 'archived')?.value && !statusFilters.find((filter) => filter.id === 'active')?.value) {
      return true;
    }
    return statusFilters.find((filter) => filter.id === 'archived')?.value;
  }, [statusFilters]);

  const shouldIncludeActive = useMemo(() => {
    if (!statusFilters.find((filter) => filter.id === 'archived')?.value && !statusFilters.find((filter) => filter.id === 'active')?.value) {
      return true;
    }
    return statusFilters.find((filter) => filter.id === 'active')?.value;
  }, [statusFilters]);

  const resourceFilter = useMemo(
    () => ({
      latestOnly: true,
      includeArchived: shouldIncludeArchived,
      includeActive: shouldIncludeActive,
      searchTerm: debouncedSearchTerm,
      types: [FormType.Resource],
      sortBy: sortBy,
      pageSize: 15,
      pageNumber: currentPage,
    }),
    [currentPage, debouncedSearchTerm, shouldIncludeActive, shouldIncludeArchived, sortBy],
  );

  const filterResources = useCallback(() => {
    const scrollTop = pageContentRef.current?.scrollTop || 0;

    setLoading(true);
    templateService.getAllTemplatesPaged(resourceFilter, false).then((res) => {
      setResources((prev) => {
        const formsPaged = { ...res };
        if (prev?.data && resourceFilter.pageNumber > 1) {
          formsPaged.data = [...prev.data, ...res.data];
        }
        return formsPaged;
      });
      setLoading(false);

      nextTick(() => {
        pageContentRef.current?.scrollTo({ top: scrollTop });
      });
    });
  }, [resourceFilter, templateService]);

  useEffect(() => {
    filterResources();
  }, [filterResources]);

  const doArchive = (formId: string) => {
    templateService.archiveTemplate(formId).then(() => {
      filterResources();
    });
  };

  const doRestore = (formId: string) => {
    templateService.restoreTemplate(formId).then(() => {
      filterResources();
    });
  };

  const applyStatusFilter = (filters: Option<string, boolean>[]) => {
    setCurrentPage(1);
    setStatusFilters(filters);
  };

  return (
    <div className="flex h-full flex-col pt-6" ref={pageContentRef}>
      <div className="flex justify-between">
        <Heading size={HeadingSize.H3}>{t('resources:templates-heading')}</Heading>
        <div className="flex justify-between gap-4">
          <div className="w-80">
            <SearchInput
              placeholder={t('resources:search')}
              value={resourceSearchPhrase}
              onChange={(e) => {
                setCurrentPage(1);
                setResourceSearchPhrase(e.target.value);
              }}
              data-cy="search-resource"
            />
          </div>
          <Button
            onClick={() =>
              currentClient ? navigate(`/clients/${currentClient.id}/resource-builder`) : navigate(`/builder/${currentTenantId}/resource-builder`)
            }
            type={ButtonType.PRIMARY}
            data-cy="create-new-resource"
          >
            <PlusIcon className="mr-2 w-4" /> {t('resources:buttons.create')}
          </Button>
        </div>
      </div>
      <div className="bg-background-1 -mx-6 h-full p-8 pt-4">
        <div className="flex gap-2">
          <FilterTag
            tag={{
              id: 'status',
              text: t(`common:list.filter.status`, { status: statusFilters.filter((filter) => filter.value)?.length || '' }),
              value: 'status',
            }}
            mode={FilterSelectMode.Multi}
            onFiltersChange={applyStatusFilter}
            options={statusFilters}
          />
        </div>
        <div className="flex-grow pt-4">
          {resources && (
            <ResourceTemplateList
              onArchive={doArchive}
              onRestore={doRestore}
              resourcesPaged={resources}
              sortBy={sortBy}
              onSort={onSortBy}
              onLoadMore={setCurrentPage}
              isLoading={loading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ResourceTemplates;
